<script setup lang="ts">
import { onMounted, ref, computed } from 'vue'
import { Typography } from '@temperworks/components'
import { ProgressCircleVariant, TypographyType } from '@temperworks/types'

interface Props {
  variant: ProgressCircleVariant
  percentage: number
  text?: string
}
const props = defineProps<Props>()

const circleSize = ref<number>(36)
const strokeDashOffset = ref<number>(100)
const typographyVariant = ref<TypographyType>(TypographyType.footnoteMedium)

const strokeDashValue = computed(() => {
  if (props.variant.includes('medium')) {
    return 140 / 100 * props.percentage
  } else if (props.variant.includes('large')) {
    return 240 / 100 * props.percentage
  }

  return props.percentage
})

onMounted(() => {
  if (props.variant.includes('medium')) {
    circleSize.value = 48
    strokeDashOffset.value = 140
    typographyVariant.value = TypographyType.bodySmallMedium
  }
  if (props.variant.includes('large')) {
    circleSize.value = 80
    strokeDashOffset.value = 240
    typographyVariant.value = TypographyType.subtitle
  }
})
</script>

<template>
  <div
    class="progressCircle"
    :class="{ [props.variant]: true, 'completed': props.percentage === 100}"
  >
    <svg class="progressCircle__svg">
      <circle
        class="progressCircle__background"
        :cx="circleSize / 2"
        :cy="circleSize / 2"
        :r="(circleSize / 2) - 3"
      />
      <circle
        class="progressCircle__overlay"
        :style="{ 'strokeDasharray': `${strokeDashValue} ${strokeDashOffset}` }"
        :cx="circleSize / 2"
        :cy="circleSize / 2"
        :r="(circleSize / 2) - 3"
      />
    </svg>
    <div class="progressCircle__content">
      <Typography
        v-if="props.text"
        class="progressCircle__text"
        :content="props.text"
        :variant="typographyVariant"
      />
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use './ProgressCircle.scss';
</style>
